<template>
  <div>
    <router-view />
    <CustomerFooter />
  </div>
</template>

<script>
import CustomerFooter from "@/components/Footers/CustomerFooter.vue";

export default {
  name: "CustomerLayout",
  components: { CustomerFooter }
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/_variables.scss";
</style>
